<template>
	<div>
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
				    <div class="card-body">
				        <h5 class="card-title">
				        	Thống kê doanh thu
				        </h5>
				        <div class="table-responsive">
				        	<table class="table table-bordered table-hover">
				        		<thead>
				        			<tr>
				        				<th>Loại</th>
				        				<th>Ngày {{ day }}</th>
				        				<th>Tháng {{ month }}</th>
				        			</tr>
				        		</thead>
				        		<tbody>
				        			<tr>
				        				<td>Nạp tiền thông qua hệ thống Momo</td>
				        				<td>
				        					<span class="badge bg-danger">+ 500,000 VND</span>
				        				</td>
				        				<td>
					        				<span class="badge bg-primary">+ 9,484,515 VND</span>
					        			</td>
				        			</tr>
				        		</tbody>
				        	</table>
				        </div>
				    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped=""></style>

<script>
	export default {
		data () {
			return {
				day: new Date().getDay(),
				month: new Date().getMonth(),
			}
		},
		methods: {
			
		}
	}
</script>